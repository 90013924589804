<template>
	<div id="liveList">
		<page-live-list ref="live" :is-shop="1"></page-live-list>
	</div>
</template>


<script>
	import PageLiveList from '@/components/layout/live/page-live-list.vue'
	export default{
		components:{
			PageLiveList
		},
	}
	
</script>

<style>
	
</style>
